import React from 'react'
import ReactPlayer from 'react-player';
import { Col, Container, Row } from 'reactstrap'
import { CampusContext } from '../index';

export default function AboutSection(
    {
        href,
        titleWhitePart,
        text,
        image,
        video = false,
        alt,
        reverse,
        buttons = {
            primaryButton: {
                text: '',
                href: '',
                backgroundColor: '',
            },
            secondaryButton: {
                text: '',
                href: '',
                backgroundColor: '',
            }
        }
    }) {

    const context = React.useContext(CampusContext);

    const { toggleSatis } = context;

    const {
        primaryButton = {
            text: '',
            href: '',
            backgroundColor: '',
        },
        secondaryButton = {
            text: '',
            href: '',
            backgroundColor: '',
        }
    } = buttons;

    return (
        <Container id={href} className="box-style no-p m-t-40">
            <Row className={reverse && 'flex-row-reverse'}>
                <Col xs={12} sm={12} md={5} className="no-p">
                    {video ?
                        <ReactPlayer
                            muted={true}
                            loop={true}
                            className="my-auto"
                            url={video}
                            width="489px"
                            height="487px"
                            playing={true}
                            style={{ height: "487px", width: "489px", objectFit: "cover" }}
                            controls={false}
                        /> : <img
                            src={image}
                            className="my-auto"
                            alt={alt}
                            style={{ height: "487px", width: "489px", objectFit: "cover" }}
                        />}
                </Col>
                <Col xs={12} sm={12} md={7} className="no-p p-40">
                    <div className={reverse ? "about-title-reverse" : "about-title"} style={{ position: 'relative' }}>
                        <h1><color className="text-white">{titleWhitePart}</color></h1>
                    </div>
                    <div className="about-para-line"></div>
                    <div className="about-para-line-reverse"></div>
                    <div className={reverse ? "about-para-reverse" : "about-para"}>
                        <p>
                            {text}
                        </p>
                    </div>
                    <Row className='buttons-container' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginLeft: reverse ? 40 : 0 }}>
                        {
                            (buttons && primaryButton.text != '') &&
                            < Col xs={12} sm={12} md={6} className="no-p" style={{ margin: 8 }}>
                                <a
                                    style={{ width: '100%' }}
                                    type="button"
                                    color={primaryButton.backgroundColor}
                                    href={primaryButton.href}
                                    target="_blanck"
                                    rel="noopener noreferrer"
                                    className={`btn btn-${primaryButton.backgroundColor}`}
                                    onClick={toggleSatis}
                                >
                                    {primaryButton.text}
                                </a>
                            </Col>
                        }
                        {
                            (buttons && secondaryButton.text != '') &&
                            < Col xs={12} sm={12} md={6} className="no-p" style={{ margin: 8 }}>
                                <a
                                    style={{ width: '100%' }}
                                    color={secondaryButton.backgroundColor}
                                    href={secondaryButton.href}
                                    type="button"
                                    target="_blanck"
                                    rel="noopener noreferrer"
                                    onClick={toggleSatis}
                                    className={`btn btn-${secondaryButton.backgroundColor}`}
                                >
                                    {secondaryButton.text}
                                </a>
                            </Col>
                        }
                    </Row>
                </Col >
            </Row >
        </Container >
    )
}
