import React from "react";
import ReactPlayer from "react-player";
import { analytics } from "../../firebase";
import splashScreen from "../../assets/gif/splash-2.gif";
import inmaculadaLogo from "../../assets/img/inmaculada-logo-white.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "./index.css";
import logo from "../../assets/Fav/inmaculadaFavicon.ico";

export default function Home() {
  const setPage = () => {
    document.title = "Inmaculada Films Art Transmedia";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = logo;
  };

  React.useEffect(() => {
    setPage();
    analytics.logEvent("first_time_visit", {
      first_time_visit: true,
    });
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url('${splashScreen}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 0,
        }}
      />
      <HomeNavbar />
    </div>
  );
}

export const HomeNavbar = () => {
  const contactWays = [
    {
      name: "PATREON: Inmaculada FAT",
      url: "https://www.patreon.com/user?u=72697259",
    },
    {
      name: "E-mail: inmaculadafilms@gmail.com",
      url: "mailto:inmaculadafilms@gmail.com",
    },
    {
      name: "Celular: +57 313 354 6955",
      url: "https://wa.me/+573133546955",
    },
  ];

  const serviceList = {
    servicesTitle: "Servicios",
    services: [
      "Conceptualización de ideas y guion para cine, t.v.artes escénicas y proyectos transmediales",
      "Pre producción – producción y post producción para cine, t.v.artes escénicas y proyectos transmediales",
      "Casting y direccion de actores",
      "Compañía teatral ",
    ],
    newServicesTitle: "Nuevos Servicios",
    newServices: [
      "Animation",
      "Compositing.Particles VFX",
      "Explotion, fire, smoke simulation",
      "Rigging.Lighting.Rendering",
    ],
  };

  const [popup, setPopup] = React.useState({
    show: false,
    content: <div></div>,
  });

  const navItem = [
    {
      title: "Inmaculada Films Art Transmedia",
      link: "/",
      popup: {
        show: false,
        content: <div></div>,
      },
    },
    {
      title: "Quienes somos y nuestros productos",
      link: "",
      popup: {
        show: true,
        content: (
          <Container style={{ padding: 16 }} className="m-8 py-8 ">
            <div className="w-full py-8">
              <h1 className="font-bold text-2xl w-full text-center">
                QUIENES SOMOS
              </h1>
            </div>
            <Row>
              <Col style={{ borderRadius: 24 }}>
                <ReactPlayer
                  url="https://youtu.be/yHKKsZrIxXE"
                  width="100%"
                  stopOnUnmount={true}
                  pip={true}
                />
              </Col>
              <Col className="content-center">
                <div>
                  <p style={{ margin: 16 }} className="text-sm mx-4">
                    Inmaculada Films Art Transmedia SAS es una empresa dedicada
                    a la creación de contenidos escénicos, audiovisuales y
                    transmediales. Nace el 13 de junio de 2013 bajo la razón
                    social Santofilms SAS. Ha producido varios cortometrajes
                    premiados, novedosos programas para televisión pública,
                    series web y montajes escénicos de mediano y gran formato
                    que han participado de festivales como el Festival
                    Iberoamericano de Bogota 2018. Su más reciente producción es
                    Campus Stellae un innovador proyecto escénico que además de
                    ser un espectáculo de gran formato, es un videojuego, un
                    ebook, un cortometraje de animación y un libro pop up.
                    Campus Stellae obtuvo el premio “El arte y la cultura se
                    crean en casa” de la Secretaria de Cultura, recreación y
                    deporte de Bogotá 2020. Mención de honor por su calidad e
                    innovación en la convocatoria Crea Digital 2021 del Min TIC.
                    Gobierno de Colombia. Reconocimiento especial en el Festival
                    de Échele Cabeza 2021. Ganador del Premio Bogotá Creactiva
                    2021, como reconocimiento a su proceso de activación y
                    crecimiento en medio de la pandemia. Ganador Beca es Cultura
                    Local Engativá 2021. Selección oficial Festival
                    Internacional de cine de Animación. El ojo iluso. 2022.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        ),
      },
    },
    {
      title: "Nuevos servicios",
      link: "",
      popup: {
        show: true,
        content: (
          <Row style={{ margin: 32, paddingVertical: 32 }}>
            <Col>
              <ReactPlayer
                url="https://youtu.be/B17JTuy3Uxc"
                width="100%"
                stopOnUnmount={true}
                pip={true}
              />
            </Col>
            <Col md={3}>
              <div className="w-full py-8">
                <h1 className="font-bold text-2xl w-full">
                  {serviceList.servicesTitle}
                </h1>
              </div>
              <ul>
                {serviceList.services.map((serv) => (
                  <li className="list-disc" key={serv}>
                    <p>{serv}</p>
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={3}>
              <div className="w-full py-8">
                <h1 className="font-bold text-2xl w-full">
                  {serviceList.newServicesTitle}
                </h1>
              </div>
              <ul>
                {serviceList.newServices.map((serv) => (
                  <li className="list-disc" key={serv}>
                    <p>{serv}</p>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        ),
      },
    },
    {
      title: "Campus Stellae",
      link: "/campusstellae",
      popup: {
        show: false,
        content: <div></div>,
      },
    },
    {
      title: "Comadreja",
      link: "/comadreja",
      popup: {
        show: false,
        content: <div></div>,
      },
    },
    {
      title: "Contactenos",
      link: "",
      popup: {
        show: true,
        content: (
          <Row
            style={{
              margin: 16,
              paddingTop: 24,
              paddingBottom: 24,
              height: "80%",
            }}
          >
            <Col md={8}>
              <img
                src={inmaculadaLogo}
                alt="inmaculadaLogo"
                style={{ width: "100%" }}
              />
            </Col>
            <Col md={4}>
              <div className="py-8">
                <h1 className="font-bold text-2xl w-full">Contactenos</h1>
              </div>
              <ul>
                {contactWays.map((way) => (
                  <li
                    className="list-disc"
                    key={way}
                    style={{ listStyleType: "circle" }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={way.url}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {way.name}
                    </a>
                  </li>
                ))}
              </ul>
              <div></div>
            </Col>
          </Row>
        ),
      },
    },
  ];

  const backgroundLinks = [
    "https://youtu.be/yHKKsZrIxXE",
    "",
    "https://campusstellae7.com.co/",
  ];

  return (
    <>
      <Row
        style={{
          top: 0,
          left: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          paddingRight: 24,
          paddingLeft: 24,
          zIndex: 4,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        {navItem.map((item, index) => {
          return (
            <Link
              key={index}
              style={{
                flex: 1,
                padding: 10,
                zIndex: 2,
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
              to={item.link ?? null}
              href={item.link}
              onClick={() => setPopup(item.popup)}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  pointerEvents: "auto",
                }}
              >
                {item.title}
              </p>
            </Link>
          );
        })}
      </Row>
      {popup.show && (
        <div
          onClick={() => setPopup({ show: false, content: <div></div> })}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 3,
          }}
        >
          <div
            style={{
              margin: "auto",
              marginTop: "5%",
              width: "90%",
              zIndex: 3,
            }}
            id="modalContainer"
          >
            {popup.content}
          </div>
        </div>
      )}
    </>
  );
};
