import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { projectConstants } from "./Constants/Es/index.js";
import Home from "./pages/Home/index.js";
import GalleryPage from "./pages/Campus/ComponentsHTML/Gallery.js";
import Campus from "./pages/Campus/index.js";
import Comadreja from './pages/Comadreja/index.js'

export default function App() {
  console.log(JSON.stringify(projectConstants));

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/comadreja",
      element: <Comadreja />,
    },
    {
      path: "/campusstellae",
      element: <Campus />,
    },
    {
      path: "/campusstellae/gallery",
      element: (
        <div className="p-20 ng-scope">
          <div className="spacer-40"></div>
          <GalleryPage />
          <div className="spacer-40"></div>
        </div>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
