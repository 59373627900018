import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { CampusContext } from '../index';
export default function Modals({ modal, toggle, modalSatis, toggleSatis }) {
    const context = React.useContext(CampusContext);
    const { ticket, satisfaction } = context.projectConstants.modals;
    return (
        <div>
            <Modal style={{ backgroundColor: '#0e0e10' }} id="modal" isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
                toggle={toggle}>
                <ModalBody style={{ backgroundColor: '#0e0e10', color: "white" }}>
                    {ticket.title}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: '#0e0e10', color: "white" }}>
                    <a
                        style={{ color: "white" }}
                        className="btn btn-warning"
                        color="warning"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ticket.firstButtonLink}>
                        {ticket.firstButtonText}
                    </a>
                    <Button color="secondary-outline" style={{ color: "white" }} onClick={toggle}>
                        {ticket.secondButtonText}
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal style={{ backgroundColor: '#0e0e10' }} id="modal" isOpen={modalSatis} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
                toggle={toggleSatis}>
                <ModalBody style={{ backgroundColor: '#0e0e10', color: "white" }}>
                    {satisfaction.title}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: '#0e0e10', color: "white" }}>
                    <a
                        style={{ color: "white" }}
                        className="btn btn-warning"
                        color="warning"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={satisfaction.firstButtonLink}>
                        {satisfaction.firstButtonText}
                    </a>
                    <Button color="secondary-outline" style={{ color: "white" }} onClick={toggleSatis}>
                        {satisfaction.secondButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
