import React from "react";
import comadrejaGif from "../../assets/gif/comadreja.gif";
import logo from "../../assets/Fav/inmaculadaFavicon.ico";
import { HomeNavbar } from "../Home";

export default function Comadreja() {
  const setPage = () => {
    document.title = "";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = logo;
  };

  React.useEffect(() => {
    setPage();
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url('${comadrejaGif}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 0,
        }}
      />
      <HomeNavbar />
    </div>
  );
}
