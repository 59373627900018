import React, { useContext } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CampusContext } from '../index';

export default function TeamCarousel() {

    const context = useContext(CampusContext);
    const { responsive, teamList } = context.projectConstants;

    return <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="mt-5"
    >
        {
            teamList.map((team) => {
                return (
                    < div className={team.name} style={{ alignItems: 'center', }} >
                        <h4 >{team.name}</h4>
                        <p >{team.paticipation}</p>
                    </div >

                );
            })
        }
    </Carousel>;

}