import React, { createContext, useEffect, useMemo, useState } from 'react';
import { projectConstants as EnglishConstants } from '../../Constants/En';
import { projectConstants as SpanishConstants } from '../../Constants/Es';
import { analytics } from '../../firebase';
import AboutSection from './ComponentsHTML/AboutSection';
import BlogSection from './ComponentsHTML/BlogSection';
import FirstSection from './ComponentsHTML/FirstSection';
import Footer from './ComponentsHTML/Footer';
import Header from './ComponentsHTML/Header';
import Modals from './ComponentsHTML/Modals';
import NavBar from './ComponentsHTML/NavBar';
import PortfolioSection from './ComponentsHTML/PortfolioSection';
import Team from './ComponentsHTML/Team';
import './index.css';
import logo from '../../assets/Fav/campusFavicon.ico';

export const CampusContext = createContext({
  projectConstants: SpanishConstants,
});

export default function Campus() {

  const setPage = () => {
    document.title = "Campus Stellae";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = logo;
  }

  useEffect(() => {
    setPage();
    analytics.logEvent('first_time_visit', {
      first_time_visit: true,
    });
  }, [])

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [modalSatis, setModalSatis] = useState(false);

  const toggleSatis = () => setModalSatis(!modalSatis);


  const [constantsByLanguaje, setConstantsByLanguaje] = useState(SpanishConstants)

  const { project, serie, transmediaProject, history, show, videoGame, book, galleryImages, proccessImages, selection, corto, awards, additionals1, additionals2, } = constantsByLanguaje;

  const setConstantsByLanguajeFunction = (languaje) => {
    switch (languaje) {
      case 'es':
        setConstantsByLanguaje(SpanishConstants)
        break;
      case 'en':
        setConstantsByLanguaje(EnglishConstants)
        break;
      default:
        setConstantsByLanguaje(SpanishConstants)
        break;
    }
  }

  const memoValue = useMemo(
    () => ({
      projectConstants: constantsByLanguaje,
      setConstantsByLanguajeFunction,
      toggle,
      toggleSatis: () => null
    }),
    [constantsByLanguaje]
  )

  return (
    <CampusContext.Provider value={memoValue}>
      <NavBar />
      <div>
        <div className="p-20 ng-scope">
          <div style={{ position: 'fixed', zIndex: 0, width: '100%', height: '100%' }}>
            <div className="about-big-stars"></div>
            <div className="about-small-stars-1"></div>
            <div className="about-small-stars-2"></div>
          </div>
          <div style={{ zIndex: 10, position: 'relative' }}>
            <div className="spacer-40"></div>
            <Header />
            <FirstSection />
            <AboutSection
              href={project.href}
              titleWhitePart={project.initialTitle}
              text={project.title + " " + project.description}
              image={constantsByLanguaje.ExperienceImage}
              alt={project.alt}
              reverse={false}
              buttons={project.buttons}
            />
            <PortfolioSection galleryImages={galleryImages} />
            <AboutSection
              href={"project"}
              titleWhitePart={transmediaProject.title}
              text={transmediaProject.description}
              image={project.image}
              alt={project.alt}
              reverse={true}
            />
            <PortfolioSection galleryImages={selection} />
            <AboutSection
              href={"history"}
              titleWhitePart={history.title}
              text={history.description}
              image={history.image}
              alt={history.alt}
              reverse={false}
            />
            <PortfolioSection galleryImages={corto} />
            <AboutSection
              href={"show"}
              titleWhitePart={show.title}
              text={show.description}
              image={show.image}
              alt={show.alt}
              reverse={true}
              buttons={show.buttons}
            />
            <PortfolioSection galleryImages={additionals1} />
            <AboutSection
              href={"serie"}
              titleWhitePart={serie.title}
              text={serie.description}
              image={serie.image}
              alt={serie.alt}
              reverse={false}
              buttons={serie.buttons}
            />
            <PortfolioSection galleryImages={additionals2} />
            <AboutSection
              href={"game"}
              titleWhitePart={videoGame.title}
              text={videoGame.description}
              image={videoGame.image}
              alt={videoGame.alt}
              reverse={true}
              buttons={videoGame.buttons}
            />
            <PortfolioSection galleryImages={proccessImages} />
            <AboutSection
              href={"book"}
              titleWhitePart={book.title}
              text={book.description}
              image={book.itemRight.image}
              alt={book.itemRight.alt}
              reverse={false}
              buttons={book.buttons}
              video={book.video}
            />
            <BlogSection awards={awards} />
            <Team />
            <Footer href={"footer"} />
          </div>
        </div >
      </div>
      <Modals
        modal={modal} toggle={toggle} modalSatis={modalSatis} toggleSatis={toggleSatis}
      />
    </CampusContext.Provider>
  );
}