import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { CampusContext } from '../index';

export default function BlogSection({ awards }) {

    const context = React.useContext(CampusContext);

    const { toggleSatis } = context;

    return (
        <div>
            <Container className="container-2 no-p">
                <Row>
                    {
                        awards.map((award) => {
                            return (
                                <Col xs={12} sm={12} md={4} className="no-p wp-blog-p bounce-hover">
                                    <div className="box-style">
                                        <a
                                            target="_blanck"
                                            rel="noopener noreferrer"
                                            onClick={toggleSatis}
                                            href={award.href}
                                        >
                                            <div className="post-thumbnail" style={{ backgroundImage: `url('${award.image}')` }}></div>
                                        </a>
                                        <div className="post-title p-30">
                                            <div className="blog-star rotate-star ng-hide"></div>
                                            {/* <div className="blog-cat cat-colour-Blog">
                                        Blog
                                    </div> */}
                                            <h6><strong>{award.name}</strong><span>...</span></h6>
                                            <h3>{award.date}</h3>
                                            <span>
                                                {award.description}
                                            </span>
                                            <span>...</span>
                                            <br /><br />
                                            <a
                                                target="_blanck"
                                                rel="noopener noreferrer"
                                                onClick={toggleSatis}
                                                href={award.href}
                                            >
                                                <p>
                                                    {award.buttonText}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}
