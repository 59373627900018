import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CampusContext } from '../index';
import HeaderImage from '../../../assets/resources/IMG_2811.png';

export default function Header() {

    const context = useContext(CampusContext)
    const { banner } = context.projectConstants;
    const { image, alt, style, subtitle, shotype, showInvitation } = banner

    return (
        <div>
            <Container className="box-style p-20" style={{ backgroundColor: 'black', borderWidth: 3, borderColor: 'white' }}>
                <Container fluid className="no-p">
                    <Row>
                        {/* <!-- Info Section--> */}
                        <Col xs={12} sm={12} md={6} style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: 'auto' }}>
                            <center>
                                <img
                                    src={image}
                                    alt={alt}
                                    style={style}
                                />
                                <h2 style={{ width: '70%', color: '#FEEB03', textShadow: '#461F68 1px 0 10px' }}>{subtitle}</h2>
                                <p style={{ width: '50%' }}>
                                    {shotype}
                                </p>
                            </center>
                        </Col>

                        {/* <!-- Graphic Section --> */}

                        <Col xs={12} sm={12} md={6} className="no-p">

                            <img
                                src={HeaderImage}
                                alt={alt}
                                style={{ ...style, width: '100%' }}
                            />
                            <p style={{ width: '80%', textAlign: 'center', marginLeft: 30, fontSize: 24 }}>
                                {showInvitation}
                            </p>
                            {/* <p style={{ width: '50%', textAlign: 'center', marginLeft: 30 }}>
                                {secondaryDescription}
                            </p> */}
                            {/* < Col xs={12} sm={12} md={6} className="no-p" style={{ margin: 8, marginLeft: 30, alignItems: 'center', width: '100%' }}>
                                <a
                                    style={{ width: '100%' }}
                                    color={primaryButton.backgroundColor}
                                    href={primaryButton.href}
                                    type="button"
                                    target="_blanck"
                                    rel="noopener noreferrer"
                                    onClick={null}
                                    className={`btn btn-${primaryButton.backgroundColor}`}
                                >
                                    {primaryButton.text}
                                </a>
                            </Col> */}
                        </Col>
                    </Row>
                </Container>

                {/* <!-- Spacer --> */}

                <div className="spacer-40"></div>

            </Container>
        </div>
    )
}
