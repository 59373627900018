import React, { useContext, useEffect, useState } from 'react';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavLink, UncontrolledDropdown } from 'reactstrap';
import Patreon from '../../../assets/img/patreon.png';
import { CampusContext } from '../index';

export default function NavBar() {
    const context = useContext(CampusContext);
    const { navItems, whoWeAre, languaje, navbar, footer } = context.projectConstants;
    const [isTop, setIsTop] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        document.addEventListener('scroll', () => {
            const tgl = window.scrollY < 25;
            setIsTop(tgl)
        });
    });

    const setLenguaje = (lan) =>
        context.setConstantsByLanguajeFunction(lan)


    return (
        <Navbar
            fixed="top"
            light expand="md"
            color={
                !isTop && !isOpen ?
                    "black" :
                    "transparent"
            }
            style={{
                backgroundColor:
                    isOpen ?
                        "black" :
                        "rgba(2,2,2,0.5)",
                width: "100%",
                zIndex: 20
            }}
        >
            <NavbarBrand
                href="/"
                style={{ color: "#FFEB00" }}
            >
                {
                    isTop ?
                        navbar.topTitle :
                        navbar.bottomTitle
                }
            </NavbarBrand>
            <NavbarToggler
                onClick={toggle}
                className="ml-100"
                style={{ backgroundColor: 'rgba(254, 233, 1, 0.5)' }}
            />
            <Collapse
                isOpen={isOpen}
                navbar color="warning"
            >
                <Nav
                    navbar
                    vertical="false"
                    style={{ width: "100%" }}
                    className="justify-content-end"
                >
                    {
                        navItems.map((navItem) => {
                            return (
                                <NavLink
                                    color="primary"
                                    key={navItem.name}
                                    href={navItem.href}
                                    className="navButton"
                                    active style={{ color: "#FFEB00" }}
                                >
                                    {navItem.name}
                                </NavLink>
                            );

                        })
                    }
                </Nav>
                <UncontrolledDropdown
                    color="transparent"
                >
                    <DropdownToggle
                        nav
                        style={{ color: "#FEEA01" }}
                    >
                        {navbar.whoWheareTitle}
                    </DropdownToggle>
                    <DropdownMenu
                        right
                        color="transparent"
                        style={{ backgroundColor: "transparent" }}
                    >
                        {
                            whoWeAre.map((item) => {
                                return (
                                    <DropdownItem
                                        key={item.name}
                                        href={item.href}
                                        style={{ backgroundColor: "transparent", color: "#FEEA01" }}
                                    >
                                        {item.name}
                                    </DropdownItem>
                                );
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown
                    color="transparent"
                >
                    <DropdownToggle
                        nav
                        style={{ color: "#FEEA01" }}
                    >
                        {languaje.buttonText}
                    </DropdownToggle>
                    <DropdownMenu
                        right
                        color="transparent"
                        style={{ backgroundColor: "transparent" }}
                    >
                        {
                            languaje.languajes.map((item) => {
                                return (
                                    <DropdownItem
                                        key={item.name}
                                        onClick={() => setLenguaje(item.value)}
                                        style={{ backgroundColor: "transparent", color: "#FEEA01" }}
                                    >
                                        {item.name}
                                    </DropdownItem>
                                );
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavLink className="navButton" target="_blanck" rel="noopener noreferrer" href={footer.contact.ways[0].url} active style={{ color: "#FFEB00" }} color="primary" >
                    <img src={Patreon} alt="patreon" style={{ width: 32, height: 32, marginLeft: 24 }} />
                </NavLink>
            </Collapse>
        </Navbar>
    );
}