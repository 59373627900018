import ebook from '../../assets/img/ebook.png'
import book from '../../assets/img/book.png'
import logo from '../../assets/img/titulo campus.png'
import secretaria from '../../assets/img/secretari.png'
import sponsors from '../../assets/img/logosponsoresWhite3.png'
import history from '../../assets/gif/historia.gif'
import show from '../../assets/gif/show.gif'
import project from "../../assets/gif/coronalengua2.gif";
import serie from '../../assets/gif/serie.gif';
import videoGame from '../../assets/img/portada juego.png';

import communicationResource1 from '../../assets/visualresources/1.png'
import communicationResource2 from '../../assets/visualresources/2.png'
import communicationResource3 from '../../assets/visualresources/3.png'
import communicationResource4 from '../../assets/visualresources/4.png'
import communicationResource5 from '../../assets/visualresources/5.png'
import communicationResource6 from '../../assets/visualresources/6.png'
import proccessPhoto1 from '../../assets/visualresources/7.png'
import proccessPhoto2 from '../../assets/visualresources/8.png'
import proccessPhoto3 from '../../assets/visualresources/9.png'
import proccessPhoto4 from '../../assets/visualresources/10.png'
import select1 from '../../assets/visualresources/11.png'
import select2 from '../../assets/visualresources/12.png'
import select3 from '../../assets/visualresources/13.png'
import select4 from '../../assets/visualresources/14.png'
import corto1 from '../../assets/visualresources/15.png'
import corto2 from '../../assets/visualresources/16.png'
import corto3 from '../../assets/visualresources/17.png'
import corto4 from '../../assets/visualresources/18.png'
import additional1 from '../../assets/visualresources/19.png'
import additional2 from '../../assets/visualresources/20.png'
import additional3 from '../../assets/visualresources/21.png'
import additional4 from '../../assets/visualresources/22.png'
import additional5 from '../../assets/visualresources/23.png'
import additional6 from '../../assets/visualresources/24.png'
import additional7 from '../../assets/visualresources/25.png'
import additional8 from '../../assets/visualresources/26.png'
import ExperienceImage from '../../assets/resources/IMG_2957.jpeg';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const commonConsts = {
    imageBooksStyle: { width: "75%", height: "75%", objectFit: "cover" },
    buttonBookStyle: { width: "75%" },
    contactWayStyle: { overflow: "hidden" },
    sponsorsStyle: { width: "100%" }
}

const galleryImages = [
    {
        image: communicationResource1
    },
    {
        image: communicationResource2
    },
    {
        image: communicationResource3
    },
    {
        image: communicationResource4
    },
    {
        image: communicationResource5
    },
    {
        image: communicationResource6
    },
]

const proccessImages = [
    {
        image: proccessPhoto1
    },
    {
        image: proccessPhoto2
    },
    {
        image: proccessPhoto3
    },
    {
        image: proccessPhoto4
    },

]

const selection = [
    {
        image: select1,
    },
    {
        image: select2,
    },
    {
        image: select3,
    },
    {
        image: select4,
    },
]
const additionals1 = [
    {
        image: additional1,
    },
    {
        image: additional2,
    },
    {
        image: additional3,
    },
    {
        image: additional4,
    },
]
const additionals2 = [
    {
        image: additional5,
    },
    {
        image: additional6,
    },
    {
        image: additional7,
    },
    {
        image: additional8,
    },
]

const corto = [
    {
        name: "corto1",
        image: corto1,
    },
    {
        name: "corto2",
        image: corto2,
    },
    {
        name: "corto3",
        image: corto3,
    },
    {
        name: "corto4",
        image: corto4,
    },
]

export {
    ebook,
    book,
    logo,
    secretaria,
    sponsors,
    history,
    show,
    project,
    serie,
    videoGame,
    responsive,
    commonConsts,
    galleryImages,
    proccessImages,
    selection,
    corto,
    additionals1,
    additionals2,
    ExperienceImage
};