import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import { CampusContext } from '../index';
import TeamCarousel from './TeamCarousel';

export default function Team() {
    const context = useContext(CampusContext);
    const { team } = context.projectConstants;
    return (
        <Container
            id="team"
            className="box-style p-40 text-center"
        >
            <h1>
                {team.title}
            </h1>
            <p>
                {team.description}
            </p>
            <TeamCarousel />
        </Container>
    );
}