import {
    ebook,
    book,
    logo,
    secretaria,
    sponsors,
    history,
    show,
    project,
    serie,
    videoGame,
    commonConsts,
    responsive,
    galleryImages,
    proccessImages,
    selection,
    corto,
    additionals1,
    additionals2,
    ExperienceImage
} from '../CommonConsts/index.js'

const team = [{
    "name": "Andrés Gaitán Duarte",
    "paticipation": "Idea original, dirección general, dramaturgia y guiones"
},
{
    "name": "Gonzalo Torres",
    "paticipation": "Dirección de arte montaje teatral y cortometraje"
},
{
    "name": "Hugo Rodríguez",
    "paticipation": "Director Escenografía montaje teatral y cortometraje"
},
{
    "name": "Ricardo Peñuela",
    "paticipation": "Artista creador montaje teatral, asistente de construcción, modelador y animador de puppets cortometraje"
},
{
    "name": "Zimons Domínguez.",
    "paticipation": "Artista creador montaje teatral, asistente de construcción, luminotécnico en montaje teatral y cortometraje"
},
{
    "name": "Angie Díaz. ",
    "paticipation": "Artista creadora montaje teatral, creación mecanismos libro pop up, redes y Eustorgio en cortometraje"
},
{
    "name": "Carlos Mario Benítez (Elias Mur)",
    "paticipation": "Musicalización y Foley montaje teatral, cortometraje, libro y videojuego"
},
{
    "name": "Foresteiz 14. Svop music",
    "paticipation": "Composición y mezcla sonora montaje teatral, cortometraje, libro y videojuego"
},
{
    "name": "Cristian Montenegro   ",
    "paticipation": "Programación y desarrollo videojuego"
},
{
    "name": "Sebastián González",
    "paticipation": "Diseño, diagramación, ilustración libro"
},
{
    "name": "Brian García",
    "paticipation": "Ilustración videojuego y libro"
},
{
    "name": "Esteban Congote",
    "paticipation": "Director de fotografía cortometraje"
},
{
    "name": "Cristian Jiménez",
    "paticipation": "Cámara y making of cortometraje"
},
{
    "name": "Cristian Jiménez",
    "paticipation": "Fotografía y video montaje teatral"
},
{
    "name": "Gaito Siete",
    "paticipation": "Artista creador montaje teatral, asistente de producción, redes, Aquileo en cortometraje"
},
{
    "name": "Luis Alvarez, Juan Alvarez y Santiago Giraldo.",
    "paticipation": "Desarrollo pagina web."
},
{
    "name": "Angélica Sandoval.",
    "paticipation": "Actriz y producción espectáculo teatral."
},
{
    "name": "Leonardo Villa.",
    "paticipation": "Artista escénico"
},
{
    "name": "José Briñez.",
    "paticipation": "Logistica y operacion Primos1999"
},
]

const whoWeAre = [
    {
        "name": "Equipo",
        "href": "#team"
    },
    {
        "name": "Contactanos",
        "href": "#footer"
    },
    {
        "name": "Galeria",
        "href": "campusstellae/gallery"
    }
]

const navItems = [
    {
        "name": "Inicio",
        "href": "/"
    },
    {
        "name": "Proyecto",
        "href": "#proyect"
    },
    {
        "name": "Cortometraje",
        "href": "#serie"
    },
    {
        "name": "VideoJuego",
        "href": "#game"
    },
    {
        "name": "Libro",
        "href": "#book"
    },
]

const awards = [
    {
        "name": 'Estimulo',
        "description": '“El arte y la cultura se crean en casa”',
        "date": "31 de julio 2020",
        "href": "https://sicon.scrd.gov.co/site_SCRD_pv/publicar.html?id=471#resoluciones",
        "image": "https://pbs.twimg.com/profile_images/1589998108166987776/oAiedlDf_400x400.jpg",
        "buttonText": "Ver más",
    },
    {
        "name": 'Mención de honor Crea Digital 2021.',
        "description": 'Categoria coproducción para la realización de contenidos transmedia.',
        "date": "9 de julio 2021.",
        "href": "https://mincultura.gov.co/planes-y-programas/programas/programa-nacional-estimulos/Documents",
        "image": "https://herramientas.datos.gov.co/sites/default/files/2022-05/Mision%20TIC_0.PNG",
        "buttonText": "Ver más",
    },
    {
        "name": 'VII Festival de cortos psicoactivos “Échele Cabeza” 2021.',
        "description": 'Finalistas. Reconocimiento especial.',
        "date": "Septiembre 14 2021",
        "href": "https://www.facebook.com/photo?fbid=10225417942411167&set=a.10201697154166286",
        "image": "https://cartelurbano.com/sites/default/files/festival_cuadrado_2.png",
        "buttonText": "Ver más",
    },
    {
        "name": 'Premio Bogotá Creactiva.',
        "date": "14 de diciembre 2021",
        "href": "https://sicon.scrd.gov.co/convocatorias/1208",
        "image": "http://2.bp.blogspot.com/-HM1HiyBraGE/T38gt9JPGWI/AAAAAAAABRY/JvRokMbsoME/s1600/bogota-creativa-y-diversa.jpg",
        "buttonText": "Ver más",
    },
    {
        "name": 'Beca Es Cultura Local.',
        "description": 'Engativá.',
        "date": "9 de febrero 2022",
        "href": "https://sicon.scrd.gov.co/site_SCRD_pv/publicar.html?id=471#resoluciones",
        "image": "https://cloudfront-us-east-1.images.arcpublishing.com/infobae/7W47G5AC6FGL7IH576NHJ6YVVQ.jpeg",
        "buttonText": "Ver más",
    },
    {
        "name": 'Selección oficial Festival de cine de animación El ojo Iluso.',
        "date": "Abril 7 2021",
        "href": "",
        "image": "https://artout.news/wp-content/uploads/2020/07/Animation-Film-Festival-El-Ojo-Iluso-1.jpg",
    },
]

const modals = {
    ticket: {
        title: "Inscríbete aquí y recibe un pase doble para el espectáculo teatral en 2021. Además disfruta de la serie web, descarga el videojuego y el E-book gratis! ¡Gran lanzamiento 4 y 5 de Diciembre 2020!\n\nProyecto ganador “El arte y la cultura se crean en casa” de la Secretaria de cultura, recreación y deporte.Alcaldía Mayor de Bogotá.",
        firstButtonText: "Ir al formulario",
        firstButtonLink: "https://docs.google.com/forms/d/e/1FAIpQLSf1s2C3oZ1QN916pMa8W8PfME6m0tmuLe1MdSDy7i0jTmPO8Q/viewform",
        secondButtonText: "Cancelar",
    },
    satisfaction: {
        title: '¿Disfrutaste de Campus Stellae? Queremos saber tu opinión.',
        firstButtonText: "SI, CLARO",
        firstButtonLink: "https://docs.google.com/forms/d/e/1FAIpQLSf1s2C3oZ1QN916pMa8W8PfME6m0tmuLe1MdSDy7i0jTmPO8Q/viewform",
        secondButtonText: "AHORA NO",
    }
}

export const projectConstants = {
    navbar: {
        whoWheareTitle: "¿Quiénes somos?",
        topTitle: "Bienvenido a",
        bottomTitle: "Campus Stellae",
        rateExperience: <div color="warning">
            Boletas gratis
            <br />
            aquí
        </div>
        ,
        rateExperienceURL: "https://docs.google.com/forms/d/e/1FAIpQLSf1s2C3oZ1QN916pMa8W8PfME6m0tmuLe1MdSDy7i0jTmPO8Q/viewform",
    },
    banner: {
        image: logo,
        alt: "Logo campus stellae",
        style: { width: "50%" },
        subtitle: 'La farsa tragicómica de Aquileo y Eustorgio',
        shotype: 'ESPECTÁCULO TEATRAL. VIDEOJUEGO. CORTOMETRAJE. EBOOK/ LIBRO POP UP.INSTALACION',
        showInvitation: "Selección oficial XVIII Festival de Teatro y Circo de Bogotá. Teatro el Ensueño. Agosto 20/2023.",
        showStarted: "Corre, ya empezó la función",
        secondaryDescription: "Temporada virtual. \n¡Todos los contenidos gratis!\nDel 15 al 18 de septiembre\nBeca Se Abre El Telón 2022",
        buttons: {}
    },
    book: {
        title: "Libro",
        description: "La experiencia de Campus Stellae se traslada a un espectacular libro, con los personajes ilustrados y mecanismos pop up increíbles.Además permite la participación interactiva de los lectores.Estará disponible de forma virtual y física.El libro físico podrás encontrarlo en una de las bibliotecas de Bibliored o descargar el E- book gratis aquí: ",
        video: "https://youtu.be/m782NhkCqZ4",
        itemLeft: {
            title: "Ebook",
            url: "https://firebasestorage.googleapis.com/v0/b/campus-stellae-d5a2e.appspot.com/o/ebook%2FCampusStellae.pdf?alt=media&token=fd9f1a24-5b67-47ba-b20a-88bff0adfde6",
            buttonText: "Ver e-book",
            image: ebook,
            event: "Ver ebook",
            alt: "e-book campus stellae",
            imageStyle: commonConsts.imageBooksStyle,
            buttonStyle: commonConsts.buttonBookStyle,
        },
        itemRight: {
            title: "Pop up",
            url: "https://catalogo.biblored.gov.co/acervo/165881",
            buttonText: "Reservar gratis en Bibliored",
            image: book,
            event: "ver popup",
            alt: "libro pop up campus stellae",
            imageStyle: commonConsts.imageBooksStyle,
            buttonStyle: commonConsts.buttonBookStyle,
        },
        buttons: {
            primaryButton: {
                text: "Ver e-book",
                href: "https://firebasestorage.googleapis.com/v0/b/campus-stellae-d5a2e.appspot.com/o/ebook%2FCampusStellae.pdf?alt=media&token=fd9f1a24-5b67-47ba-b20a-88bff0adfde6",
                backgroundColor: 'warning',
            },
            secondaryButton: {
                text: "Reservar gratis en Bibliored",
                href: "https://catalogo.biblored.gov.co/acervo/165881",
                backgroundColor: 'warning',
            }
        }
    },
    footer: {
        logo: {
            image: logo,
            alt: "Campus stellae",
            style: { width: "100%" }
        },
        contact: {
            title: "Contacto:",
            listStyle: { color: "white" },
            ways: [
                {
                    name: "PATREON: Inmaculada FAT",
                    url: "https://www.patreon.com/user?u=72697259",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "FACEBOOK: Campus Stellae",
                    url: "https://www.facebook.com/campus.stellae.92",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "INSTAGRAM: Campusstellae77",
                    url: "https://www.instagram.com/campusstellae77/",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "Campusstellae7@gmail.com",
                    url: "mailto:campusstellae7@gmail.com",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "Celular: 3133546955",
                    url: "https://wa.me/+573133546955",
                    style: commonConsts.contactWayStyle
                },
            ]
        },

        sponsors: {
            title: "Sponsors",
            sponsorsList: [
                {
                    alt: "Fraternidad Magna",
                    image: sponsors,
                    style: commonConsts.sponsorsStyle,
                },
                {
                    alt: "Secretaria de cultura, recreacion y deporte",
                    image: secretaria,
                    style: commonConsts.sponsorsStyle,
                },
            ]
        }
    },
    transmediaProject: {
        title: "Proyecto interdisciplinar",
        description: "Campus Stellae es un innovador proyecto escénico que aprovechó la situación de emergencia\nsanitaria y cierre de escenarios de 2020, para expandirse al universo audiovisual, literario,\nde videojuego y virtual, brindando un entretenimiento novedoso y multiplataforma para todo público.\nEsta idea transmedial escrita y dirigida por Andrés Gaitán Duarte, es una producción de la Magna \nFraternidad Teatral e Inmaculada Films Art Transmedia SAS, con el apoyo de la SCRD de la Alcaldía de Bogotá.\nRecibió Mención de honor por su calidad e innovación en la convocatoria Crea Digital 2021 del Min TIC.\nGobierno de Colombia.Reconocimiento especial en el Festival de cortos Echelé Cabeza 2021.\nGanador del Premio Bogotá Creactiva 2021, como reconocimiento a su proceso de activación y crecimiento en medio de la pandemia."
    },
    project: {
        initialTitle: "Experiencia Transmedia",
        title: "Vive la experiencia transmedial de Campus Stellae.",
        subtitle: "Un innovador proyecto escénico que además de ser un espectáculo de gran formato, es un videojuego, un ebook, un cortometraje de\nanimación y un libro pop up.",
        description: "Campus Stellae obtuvo el premio “El arte y la cultura se crean en casa” de la Secretaria de Cultura, recreación y deporte de Bogotá 2020. Mención de honor por su calidad e innovación en la convocatoria Crea Digital 2021 del Min TIC. Gobierno de Colombia. Reconocimiento especial en el Festival de Echele Cabeza 2021. Ganador del Premio Bogotá Creactiva 2021, como reconocimiento a su proceso de activación y crecimiento en medio de la pandemia.",
        image: project,
        alt: "Espectaculo Campus stellae",
        style: { width: "100%" },
        buttons: {}
    },
    history: {
        title: "Sinopsis",
        description: "Campus Stellae cuenta la historia de Aquileo, quien vive con su hermano Eustorgio en un pequeño apartamento. Él es un artista diletante sin trabajo, mientras que Aquileo es un empleado de servicios que busca la iluminación espiritual. Una noche Aquileo sueña con un espíritu que le dice que pronto va a morir y que debe ir al desierto de la Tatacoa a buscar la salvación.\nEn su búsqueda trascendental Aquileo se topará con personajes extraños, grotescos y monstruosos, que hacen parte de una fauna extraordinaria, combinada con imágenes y símbolos propios del sueño, así como obstáculos o pruebas simbólicas para alcanzar la iluminación espiritual. Los personajes y monstruos que se le aparecen a Aquileo resultan ser manifestaciones de su propio ego, haciéndole ver la naturaleza de su mente y de su espíritu.",
        image: history,
        alt: "Historia campus stellae",
        style: { width: "100%" },
    },
    show: {
        title: "Sobre el espectáculo teatral",
        description: "Campus Stellae es un espectáculo de gran formato, de 70 minutos de duración. Es una combinación de varias formas y técnicas escénicas contemporáneas como el uso de máscaras completas, manipulación de objetos, muñecones, teatro gestual, danza urbana (Dubstep dance, popping dance). Uso de materiales como papel y tela quirúrgica, para darle un tratamiento plástico y escenográfico inusual. Diálogos grabados con voces distorsionadas, música inspirada en ritmos del mundo y sonido envolvente 5.1 para dar una sensación cercana al cine.",
        imageStyle: { height: "100%", width: "100%", objectFit: "cover" },
        url: "https://www.youtube.com/watch?v=jjWuXTNaZqo",
        alt: "Espectaculo teatral Campus stellae",
        buttonStyle: { width: "100%" },
        buttonText: "Ver tráiler",
        image: show,
        buttons: {
            primaryButton: {
                text: "Ver tráiler",
                href: "https://www.youtube.com/watch?v=jjWuXTNaZqo",
                backgroundColor: "danger",
            }
        }
    },
    team: {
        title: "¿Quiénes somos?",
        description: "Somos una fraternidad de artistas creadores, profesionales de distintas áreas, que alrededor de  una dramaturgia hemos colaborado para hacerla realidad y hacerla cada vez más grande.\nLa producción general ha estado a cargo de Inmaculada films art transmedia SAS , con el apoyo incondicional de Café Cinema y Red Fibras."
    },
    serie: {
        title: "Cortometraje surreal",
        description: <div>
            <h5 style={{ display: 'inline' }}>
                {"Género: "}
            </h5>
            <p style={{ display: 'inline' }}>
                Comedia surreal.
            </p>
            <br />
            <h5 style={{ display: 'inline' }}>
                {"Animación en Stop motion. "}
            </h5>
            <br />
            <h5 style={{ display: 'inline' }}>
                {"Duración: "}
            </h5>
            <p style={{ display: 'inline' }}>
                11:55
            </p>
            <br />
            <h5 style={{ display: 'inline' }}>
                {"Sinopsis: "}
            </h5>
            <br />
            <p style={{ display: 'inline' }}>
                Aquileo y Eustorgio están encerrados en su habitación cumpliendo la cuarentena. Aquileo en
                medio del confinamiento empieza a tener alucinaciones místicas que le hacer reflexionar sobre su existencia
                mortal y lo acercan a sus monstruos interiores para liberarse de ellos y acceder por un momento al plano de lo
                divino.
            </p>
        </div>,
        image: serie,
        alt: "Cortometraje Campus stellae",
        buttons: {
            primaryButton: {
                text: 'Ver trailer',
                href: 'https://youtu.be/CHOX2JX6ooI',
                backgroundColor: 'danger',
            }
        }
    },
    videoGame: {
        title: "Video juego",
        description: "Videojuego en 2D, que puede ser descargado de forma gratuita para\nAndroid y PC El juego consta de 4 mundos en los que Aquileo se va\na encontrar con sus monstruos interiores los cuales debe vencer\npara trascender.En cada universo estará la presencia del\ncoronavirus(covid 19) en forma de pequeña caricatura que puede\neliminar al jugador.Los ganadores podrán obtener entradas gratis\nal espectáculo o descuentos especiales en boletería.",
        alt: "Video juego Campus stellae",
        image: videoGame,
        buttons: {
            primaryButton: {
                text: 'Ver trailer',
                href: 'https://youtu.be/RI35IcWXwKE',
                backgroundColor: 'danger',
            },
            secondaryButton: {
                text: "Descargar",
                href: 'https://play.google.com/store/apps/details?id=com.CampusStellae.Aquileo&hl=es&gl=US',
                backgroundColor: 'success',
            }
        }
    },
    teamList: team,
    whoWeAre,
    navItems,
    responsive,
    galleryImages,
    proccessImages,
    selection,
    corto,
    awards,
    additionals1,
    additionals2,
    modals,
    ExperienceImage,
    languaje: {
        buttonText: "Idioma",
        languajes: [
            {
                name: "Español",
                value: "es"
            },
            {
                name: "English",
                value: "en"
            },
        ]
    }
}
