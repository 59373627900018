import React, { useContext } from 'react';
import { Col, Container, Modal, Row } from 'reactstrap';
import { CampusContext } from '../index';

export default function Gallery() {

    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState(null)
    const context = useContext(CampusContext)
    const { galleryImages, proccessImages, selection, corto, additionals1, additionals2 } = context.projectConstants;
    
    const images = [
        ...galleryImages,
        ...selection,
        ...corto,
        ...additionals1,
        ...additionals2,
        ...proccessImages,
    ];

    const showImage = (image) => {
        setCurrentImage(image)
        setIsOpenModal(true)
    }

    return (
        <div>
            <Container className="container container-2 no-p m-t-10">
                <Row>
                    {
                        images.map((item) => (
                            <Col xs={12} sm={12} md={3} className="no-p wp-blog-p bounce-hover" onClick={() => { showImage(item.image) }}>
                                <a >
                                    <div className="box-style p-139 portfolio-item" style={{ backgroundImage: `url('${item.image}')` }}>

                                    </div>
                                </a>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
            <div>
                <Modal
                    isOpen={isOpenModal}
                    external={<button className="close" onClick={() => setIsOpenModal(false)} style={{ position: 'absolute', right: '15px', top: '15px' }}>×</button>}
                    toggle={() => setIsOpenModal(!isOpenModal)}
                >
                    <img src={currentImage} alt="" />
                </Modal>
            </div>
        </div>
    );
}