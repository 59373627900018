import { Badge } from 'reactstrap'
import {
    ebook,
    book,
    logo,
    secretaria,
    sponsors,
    history,
    show,
    project,
    serie,
    videoGame,
    commonConsts,
    responsive,
    galleryImages,
    proccessImages,
    selection,
    corto,
    additionals1,
    additionals2,
    ExperienceImage
} from '../CommonConsts/index.js'

const team = [{
    "name": "Andrés Gaitán Duarte",
    "paticipation": "Original idea, general direction, dramaturgy and scripts"
},
{
    "name": "Gonzalo Torres",
    "paticipation": "Art direction theatrical montage and short film"
},
{
    "name": "Hugo Rodríguez",
    "paticipation": "Director Scenography theatrical montage and short film"
},
{
    "name": "Ricardo Peñuela",
    "paticipation": "Theatrical montage creator, construction assistant, modeler and short film puppet animator"
},
{
    "name": "Zimons Domínguez.",
    "paticipation": "Theatrical montage creator artist, construction assistant, lighting technician in theatrical montage and short film"
},
{
    "name": "Angie Díaz. ",
    "paticipation": "Creative artist theatrical montage, creation of pop-up book mechanisms, networks and Eustorgio in short film"
},
{
    "name": "Carlos Mario Benítez (Elias Mur)",
    "paticipation": "Musicalization and Foley theatrical montage, short film, book and video game"
},
{
    "name": "Foresteiz 14. Svop music",
    "paticipation": "Composition and sound mixing theatrical montage, short film, book and video game"
},
{
    "name": "Cristian Montenegro   ",
    "paticipation": "Videogame programming and development"
},
{
    "name": "Sebastián González",
    "paticipation": "Design, layout, book illustration"
},
{
    "name": "Brian García",
    "paticipation": "Video game and book illustration"
},
{
    "name": "Esteban Congote",
    "paticipation": "Short film cinematographer"
},
{
    "name": "Cristian Jiménez",
    "paticipation": "Camera and making of short film"
},
{
    "name": "Cristian Jiménez",
    "paticipation": "Photography and video theatrical montage"
},
{
    "name": "Gaito Siete",
    "paticipation": "Creative artist theatrical montage, production assistant, networks, Aquileo in short film"
},
{
    "name": "Luis Alvarez, Juan Alvarez y Santiago Giraldo.",
    "paticipation": "Web page development."
},
{
    "name": "Angélica Sandoval.",
    "paticipation": "Actress and theater show production."
},
{
    "name": "Leonardo Villa.",
    "paticipation": "Scenic Artist"
},
{
    "name": "José Briñez.",
    "paticipation": " Logistic and operation Primos1999"
}
]

const whoWeAre = [
    {
        "name": "Team",
        "href": "#team"
    },
    {
        "name": "Contact",
        "href": "#footer"
    },
    {
        "name": "Gallery",
        "href": "campusstellae/gallery"
    }
]

const navItems = [
    {
        "name": "Home",
        "href": "/"
    },
    {
        "name": "Project",
        "href": "#proyect"
    },
    {
        "name": "Short film",
        "href": "#serie"
    },
    {
        "name": "Videogame",
        "href": "#game"
    },
    {
        "name": "Book",
        "href": "#book"
    },
]

const awards = [
    {
        "name": 'Stimulus',
        "description": '“Art and culture are created at home”',
        "date": "July 31, 2020",
        "href": "https://sicon.scrd.gov.co/site_SCRD_pv/publicar.html?id=471#resoluciones",
        "image": "https://www.culturarecreacionydeporte.gov.co/sites/default/files/campusstellae.png",
        "buttonText": "Read more",
    },
    {
        "name": 'Honorable mention Crea Digital 2021.',
        "description": 'Co-production category for the production of transmedia content.',
        "date": "July 9, 2021.",
        "href": "https://mincultura.gov.co/planes-y-programas/programas/programa-nacional-estimulos/Documents",
        "image": "https://economiaregional.co/wp-content/uploads/jet-engine-forms/1/2021/03/thumbnail_image.png",
        "buttonText": "Read more",
    },
    {
        "name": 'VII Psychoactive Short Film Festival “Échele Cabeza” 2021.',
        "description": 'Finalists. Special recognition.',
        "date": "Septiembre 14 2021",
        "href": "https://www.facebook.com/photo?fbid=10225417942411167&set=a.10201697154166286",
        "image": "https://cartelurbano.com/sites/default/files/festival_cuadrado_2.png",
        "buttonText": "Read more",
    },
    {
        "name": 'Bogota Creativa Award.',
        "date": "December 14, 2021",
        "href": "https://sicon.scrd.gov.co/convocatorias/1208",
        "image": "http://2.bp.blogspot.com/-HM1HiyBraGE/T38gt9JPGWI/AAAAAAAABRY/JvRokMbsoME/s1600/bogota-creativa-y-diversa.jpg",
        "buttonText": "Read more",
    },
    {
        "name": 'Is Local Culture Grant.',
        "description": 'Engativá.',
        "date": "February 9, 2022",
        "href": "https://sicon.scrd.gov.co/site_SCRD_pv/publicar.html?id=471#resoluciones",
        "image": "https://cloudfront-us-east-1.images.arcpublishing.com/infobae/7W47G5AC6FGL7IH576NHJ6YVVQ.jpeg",
        "buttonText": "Read more",
    },
    {
        "name": 'Official selection El ojo Iluso animation film festival.',
        "date": "April 7, 2021",
        "href": "",
        "image": "https://artout.news/wp-content/uploads/2020/07/Animation-Film-Festival-El-Ojo-Iluso-1.jpg",
    },
]

const modals = {
    ticket: {
        title: 'Sign up here and receive a double pass for the theatrical show in 2021. Also enjoy the web series, download the video game and the E-book for free! Great launch on December 4 and 5, 2020!\n\nWinning project "Art and culture are created at home" by the Secretary of Culture, Recreation and Sports. Mayor\'s Office of Bogotá.',
        firstButtonText: "Go to the form",
        firstButtonLink: "https://docs.google.com/forms/d/e/1FAIpQLSf1s2C3oZ1QN916pMa8W8PfME6m0tmuLe1MdSDy7i0jTmPO8Q/viewform",
        secondButtonText: "Cancel",
    },
    satisfaction: {
        title: 'Did you enjoy Campus Stellae? We want to know your opinion.',
        firstButtonText: "SURE",
        firstButtonLink: "https://docs.google.com/forms/d/e/1FAIpQLSf1s2C3oZ1QN916pMa8W8PfME6m0tmuLe1MdSDy7i0jTmPO8Q/viewform",
        secondButtonText: "NOT NOW",
    }
}

export const projectConstants = {
    navbar: {
        whoWheareTitle: "About us?",
        topTitle: "Welcome to",
        bottomTitle: "Campus Stellae",
        rateExperience: <Badge color="warning">
            Free tickets
            <br />
            here
        </Badge>
        ,
        rateExperienceURL: "https://docs.google.com/forms/d/e/1FAIpQLSf1s2C3oZ1QN916pMa8W8PfME6m0tmuLe1MdSDy7i0jTmPO8Q/viewform",
    },
    banner: {
        image: logo,
        alt: "Logo campus stellae",
        style: { width: "50%" },
        subtitle: 'The tragicomic farce of Aquileo and Eustorgio',
        shotype: 'THEATRICAL SHOW. VIDEOGAME. SHORT FILM. EBOOK/ POP UP BOOK. INSTALLATION',
        showInvitation: "Official selection XVIII Festival de Teatro y Circo de Bogotá. El Ensueño Theatre. August 20/2023.",
        showStarted: "Run, the show has already started",
        secondaryDescription: "virtual season. \nAll content free! \nFrom September 15 to 18 \nScholarship The Curtain Opens 2022",
        buttons: {}
    },
    book: {
        title: "Book",
        description: "The Campus Stellae experience is transferred to a spectacular book, with illustrated characters and incredible pop-up mechanisms. It also allows the interactive participation of readers. It will be available in virtual and physical form. The physical book can be found in one of the libraries of Bibliored or download the free E-book here:",
        video: "https://youtu.be/m782NhkCqZ4",
        itemLeft: {
            title: "Ebook",
            url: "https://firebasestorage.googleapis.com/v0/b/campus-stellae-d5a2e.appspot.com/o/ebook%2FCampusStellae.pdf?alt=media&token=fd9f1a24-5b67-47ba-b20a-88bff0adfde6",
            buttonText: "See e-book",
            image: ebook,
            event: "Read ebook",
            alt: "e-book campus stellae",
            imageStyle: commonConsts.imageBooksStyle,
            buttonStyle: commonConsts.buttonBookStyle,
        },
        itemRight: {
            title: "Pop up",
            url: "https://catalogo.biblored.gov.co/acervo/165881",
            buttonText: "Book free on Biblored",
            image: book,
            event: "ver popup",
            alt: "libro pop up campus stellae",
            imageStyle: commonConsts.imageBooksStyle,
            buttonStyle: commonConsts.buttonBookStyle,
        },
        buttons: {
            primaryButton: {
                text: "See e-book",
                href: "https://firebasestorage.googleapis.com/v0/b/campus-stellae-d5a2e.appspot.com/o/ebook%2FCampusStellae.pdf?alt=media&token=fd9f1a24-5b67-47ba-b20a-88bff0adfde6",
                backgroundColor: 'warning',
            },
            secondaryButton: {
                text: "Book free on Biblored",
                href: "https://catalogo.biblored.gov.co/acervo/165881",
                backgroundColor: 'warning',
            }
        }
    },
    footer: {
        logo: {
            image: logo,
            alt: "Campus stellae",
            style: { width: "100%" }
        },
        contact: {
            title: "Contact us:",
            listStyle: { color: "white" },
            ways: [
                {
                    name: "PATREON: Inmaculada FAT",
                    url: "https://www.patreon.com/user?u=72697259",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "FACEBOOK: Campus Stellae",
                    url: "https://www.facebook.com/campus.stellae.92",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "INSTAGRAM: Campusstellae77",
                    url: "https://www.instagram.com/campusstellae77/",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "Campusstellae7@gmail.com",
                    url: "mailto:campusstellae7@gmail.com",
                    style: commonConsts.contactWayStyle
                },
                {
                    name: "Celular: 3133546955",
                    url: "https://wa.me/+573133546955",
                    style: commonConsts.contactWayStyle
                }
            ]
        },

        sponsors: {
            title: "Sponsors",
            sponsorsList: [
                {
                    alt: "Fraternidad Magna",
                    image: sponsors,
                    style: commonConsts.sponsorsStyle,
                },
                {
                    alt: "Secretaria de cultura, recreacion y deporte",
                    image: secretaria,
                    style: commonConsts.sponsorsStyle,
                },
            ]
        }
    },
    transmediaProject: {
        title: "Interdisciplinary project",
        description: "Campus Stellae is an innovative stage project that took advantage of the 2020 health\nemergency situation and closure of stages, to expand into the audiovisual, literary,\nvideo game and virtual universe, providing new and multiplatform entertainment for all audiences.\nThis transmedia idea written and directed by Andrés Gaitán Duarte, it is a production of the Magna \nFraternidad Teatral e Inmaculada Films Art Transmedia SAS, with the support of the SCRD of the Mayor's Office of Bogotá.\nIt received an Honorable Mention for its quality and innovation in the Crea Digital 2021 of the TIC Min.\nGovernment of Colombia.Special recognition at the Echelé Cabeza 2021 Short Film Festival.\nWinner of the Bogotá Creactiva 2021 Award, in recognition of its activation and growth process in the midst of the pandemic."
    },
    project: {
        initialTitle: "Transmedia Experience",
        title: "Large-format show with video game, e-book, short film, pop-up book.",
        subtitle: "An innovative stage project that, in addition to being a large-format show, is a video game, an ebook, an animated short film and a pop-up book.",
        description: 'Campus Stellae won the "Art and culture are created at home" award from the Secretary of Culture, recreation and sports of Bogotá 2020. Honorable mention for its quality and innovation in the Crea Digital 2021 call of the ICT Ministry. Colombian Government. Special recognition at the Echele Cabeza 2021 Festival. Winner of the Bogotá Creactiva 2021 Award, in recognition of his activation and growth process in the midst of the pandemic.',
        image: project,
        alt: "Campus stellae show",
        style: { width: "100%" },
        buttons: {}
    },
    history: {
        title: "Sinopsis",
        description: "Campus Stellae tells the story of Aquileo, who lives with his brother Eustorgio in a small apartment. He is a jobless dilettante artist, while Achilles is a service employee seeking spiritual enlightenment. One night Aquileo dreams of a spirit that tells him that he will soon die and that he must go to the Tatacoa desert to seek salvation.\nIn his transcendental search for him, Aquileo will come across strange, grotesque and monstrous characters, who are part of an extraordinary fauna, combined with images and symbols typical of dreams, as well as obstacles or symbolic tests to achieve spiritual enlightenment. The characters and monsters that appear to Aquileo turn out to be manifestations of his own ego, making him see the nature of his mind and his spirit.",
        image: history,
        alt: "Campus stellae history",
        style: { width: "100%" },
    },
    show: {
        title: "About the theatrical show",
        description: "Campus Stellae is a 70-minute large-format show. It is a combination of various contemporary stage forms and techniques such as the use of full masks, manipulation of objects, dolls, gestural theater, urban dance (Dubstep dance, popping dance). Use of materials such as paper and surgical cloth, to give it an unusual plastic and scenographic treatment. Dialogues recorded with distorted voices, world-beat-inspired music, and 5.1 surround sound for a close-to-cinema feel.",
        imageStyle: { height: "100%", width: "100%", objectFit: "cover" },
        url: "https://www.youtube.com/watch?v=jjWuXTNaZqo",
        alt: "About the theatrical show",
        buttonStyle: { width: "100%" },
        buttonText: "Watch the trailer",
        image: show,
        buttons: {
            primaryButton: {
                text: "Watch trailer",
                href: "https://www.youtube.com/watch?v=jjWuXTNaZqo",
                backgroundColor: "danger",
            }
        }
    },
    team: {
        title: "About us?",
        description: "We are a fraternity of creative artists, professionals from different areas, who around a dramaturgy have collaborated to make it come true and make it bigger and bigger. \ NThe general production has been in charge of Inmaculada films art transmedia SAS, with the unconditional support of Café Cinema and Red Fibras."
    },
    serie: {
        title: "Surreal short film",
        description: <div>
            <h5 style={{ display: 'inline' }}>
                {"Genre: "}
            </h5>
            <p style={{ display: 'inline' }}>
                Surreal comedy.
            </p>
            <br />
            <h5 style={{ display: 'inline' }}>
                {"Stop motion animation. "}
            </h5>
            <br />
            <h5 style={{ display: 'inline' }}>
                {"Duration: "}
            </h5>
            <p style={{ display: 'inline' }}>
                11:55
            </p>
            <br />
            <h5 style={{ display: 'inline' }}>
                {"Sinopsis: "}
            </h5>
            <br />
            <p style={{ display: 'inline' }}>
                Aquileo and Eustorgio are locked in their room fulfilling the quarantine. Achilles in
                In the midst of confinement, he begins to have mystical hallucinations that make him reflect on his existence
                mortal and bring him closer to his inner monsters to get rid of them and access for a moment the plane of what
                divine.
            </p>
        </div>,
        image: serie,
        alt: "Campus stellae short film",
        buttons: {
            primaryButton: {
                text: 'Watch trailer',
                href: 'https://youtu.be/CHOX2JX6ooI',
                backgroundColor: 'danger',
            }
        }
    },
    videoGame: {
        title: "Videogame",
        description: "2D videogame, which can be downloaded for free for \ nAndroid and PC The game consists of 4 worlds in which Aquileo will \ na encounter his inner monsters which he must overcome \ nto transcend. In each universe there will be the presence of the \ ncoronavirus (covid 19) in the form of a small cartoon that can \ nkill the player. Winners will be able to get free tickets to the show \ n or special discounts at the box office.",
        alt: "Campus stellae videogame",
        image: videoGame,
        buttons: {
            primaryButton: {
                text: 'Watch trailer',
                href: 'https://youtu.be/RI35IcWXwKE',
                backgroundColor: 'danger',
            },
            secondaryButton: {
                text: "Download",
                href: 'https://play.google.com/store/apps/details?id=com.CampusStellae.Aquileo&hl=es&gl=US',
                backgroundColor: 'success',
            }
        }
    },
    navItems,
    whoWeAre,
    teamList: team,
    responsive,
    galleryImages,
    proccessImages,
    selection,
    corto,
    awards,
    additionals1,
    additionals2,
    modals,
    ExperienceImage,
    languaje: {
        buttonText: "Language",
        languajes: [
            {
                name: "Español",
                value: "es"
            },
            {
                name: "English",
                value: "en"
            },
        ]
    }
}
