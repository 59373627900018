import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CampusContext } from '../index';
import gameGif from '../../../assets/gif/game.gif'

export default function FirstSection() {

    const context = useContext(CampusContext);

    const { navItems } = context.projectConstants;

    const products = [

        {
            title: navItems[1].name,
            href: navItems[1].href,
            subtitle: "",
            colClassName: "no-p m-p-r-10",
            className: "box-style p-40 stat-bg-1 bounce-hover",
            xs: 12,
            sm: 12,
            md: 8
        },
        {
            title: navItems[2].name,
            href: navItems[2].href,
            subtitle: "",
            colClassName: "no-p m-p-r-10",
            className: "box-style p-40 stat-bg-2 stats-title-2 bounce-hover",
            xs: 12,
            sm: 12,
            md: 4,
            image: gameGif
        },
        {
            title: navItems[3].name,
            href: navItems[3].href,
            subtitle: "",
            colClassName: "no-p m-p-r-10",
            className: "box-style p-40 stat-bg-4 bounce-hover",
            xs: 12,
            sm: 12,
            md: 6,
            image: gameGif
        },
        {
            title: navItems[4].name,
            href: navItems[4].href,
            subtitle: "",
            colClassName: "no-p m-p-r-10",
            className: "box-style p-40 stat-bg-3 bounce-hover",
            xs: 12,
            sm: 12,
            md: 6
        },
    ]

    return (
        < div >
            <Container>
                <Row>
                    {
                        products.map((product, index) => {
                            return (
                                <Col
                                    key={index}
                                    className={product.colClassName}
                                    xs={product.xs}
                                    sm={product.sm}
                                    md={product.md} >
                                    <a href={product.href}>
                                        < div className={product.className} style={{ alignItems: 'center' }} >
                                            <h1 className="stats-title">{product.title}</h1>
                                            <p className="stats-subtitle">{product.subtitle}</p>
                                        </div >
                                    </a>
                                </Col >
                            )
                        })
                    }
                </Row>
            </Container>
        </div >
    )
}
