import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyB2kbYzImEG43HmTyoWXZFYmkzFyTGFHms",
    authDomain: "inmaculada-49d3d.firebaseapp.com",
    projectId: "inmaculada-49d3d",
    storageBucket: "inmaculada-49d3d.appspot.com",
    messagingSenderId: "802720614090",
    appId: "1:802720614090:web:b7c2298f8e44d450575633",
    measurementId: "G-G386RYCN1R"
};

firebase.initializeApp(firebaseConfig)
export const analytics = firebase.analytics();