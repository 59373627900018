import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CampusContext } from '../index';

export default function Footer({ href }) {

    const context = useContext(CampusContext);
    const { logo, sponsors, contact } = context.projectConstants.footer;

    return (
        <Container id={href} className="box-style p-5 m-t-40">
            <Row>
                <Col sm="6" md="4" lg="4" xl="4" className="my-auto">
                    <img
                        src={logo.image}
                        alt={logo.alt}
                        style={logo.style}
                    />
                </Col>
                <Col sm="6" md="4" lg="4" xl="4" className="my-auto">
                    <h6>{contact.title}</h6>
                    <ul style={contact.listStyle}>
                        {contact.ways.map(way =>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={way.url}
                                    style={{ textDecoration: 'none', color: 'white' }}>
                                    {way.name}
                                </a>
                            </li>
                        )}
                    </ul>
                </Col>
                <Col sm="12" md="4" lg="4" xl="4">
                    <h6>{sponsors.title}</h6>
                    <Row>
                        {sponsors.sponsorsList.map(sponsor =>
                            <img
                                src={sponsor.image}
                                alt={sponsor.alt}
                                style={sponsor.style}
                            />
                        )}
                    </Row>
                </Col>
            </Row >
            <Row>
                <Col sm="0" md="3" lg="3" xl="3" />
            </Row>
            <hr color="#1A181D" />
            <center>
                <span style={{ width: '100%', textAlign: 'center' }} role="img" aria-label="ProgressLab" id="progresslab">2022 © ProgressLab </span>
            </center>
        </Container >
    );
}