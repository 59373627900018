import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export default function PortfolioSection({ galleryImages }) {

    return (
        <div>
            <Container className="container container-2 no-p m-t-10">
                <Row>
                    {
                        galleryImages.slice(0, 4).map((item) => (
                            <Col xs={12} sm={12} md={3} className="no-p wp-blog-p bounce-hover">
                                <a href='/campusstellae/gallery' >
                                    <div className="box-style p-139 portfolio-item" style={{ backgroundImage: `url('${item.image}')` }}>
                                        <div className="overlay-box" style={{ backgroundColor: 'black', height: '100%', width: '100%' }}>
                                            {item.title}
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}
